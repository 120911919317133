/* Rectangle 3 */
#header-container {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 101;
  height: 100px;
  background: white;
  /* color: black; */
}
/* UFP method */
.menu-items {
  width: 100%;
  align-items: center;
  text-align: center;
  margin-top: 10px;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  /* color: #303030; */
}

.FP_logo {
}
.hamburguer-item {
  width: 100%;
  height: 80px;
  font-size: xx-large;
}

.ourwork {
  color: white !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown:hover .dropdown-content {
  display: block;
  visibility: visible;
  opacity: 1;
}
.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

@media only screen and (max-width: 1000px) {
  .menu-items {
    padding-bottom: 50px;
  }
  #header-container {
    height: auto;
  }
}
#header-container a:link {
  color: #303030;
}

#header-container a:visited {
  color: #000000;
}
#header-container a:hover {
  color: #70db93;
}
#header-container a:active {
  color: #303030;
}
