.select-background {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #26c485;
  width: 100%;
  height: 800px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  transform: translate3d(0, 0, 0);
  overflow-x: scroll;
}
.select-background::-webkit-scrollbar {
  display: none;
}
.select-text {
  margin-top: 200px;
  margin-left: 200px;
}
.title-small {
  padding-top: 48px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}
.title-big {
  padding-top: 12px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: normal;
  text-align: center;

  color: #ffffff;
}

.add_city_box {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 500px;
}
.world-map-select-city {
  position: fixed;
  width: 1000px;
  top: 100px;
}
.lisboa {
  position: fixed;
  width: 36px;
  height: 36px;
  left: calc(448px + 13vw);
  top: 408px;
  margin-left: -20px;
}
.boston {
  position: fixed;
  width: 36px;
  height: 36px;
  left: calc(282px + 13vw);
  top: 388px;
  margin-left: -20px;
}

.worldmap_container {
  position: relative;
  width: 1020px;
  height: 800px;
  left: 13vw;
}

.go-to-generator {
  position: fixed;
  width: 200px;
  height: auto;
  left: 122px;
  top: 588px;
  margin-left: -100px;
}
