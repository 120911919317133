.assmt-background {
  display: flex;
  background-color: #fafafa;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: 100px;
}

.textDiv {
  display: flex;
  flex-direction: column;
}
.graphsDiv {
  padding: 24px;
  width: 100%;
}

.assmt-title {
  margin-top: 12px;
  padding: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #009688;
}
.assmt-title-bigger {
  padding: 12px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: normal;
  color: #009688;
}
.assmt-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 29px;
  letter-spacing: 0.03em;

  color: black;
}

.button1 {
  flex-wrap: wrap;
  margin: 6px;
  justify-content: center;
  width: 130.08px;
  height: 43px;
  text-align: center;
  padding: 13px;
  background: #009688;
  border-radius: 2px;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: normal;

  color: #ffffff;
}

.button2 {
  margin: 6px;
  flex-wrap: wrap;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: normal;
  text-align: center;
  text-align: center;
  padding: 13px;
  color: #92cbc5;
  justify-content: center;
  width: 130.08px;
  height: 43px;

  border: 1px solid #92cbc5;
  box-sizing: border-box;
  border-radius: 2px;
}
