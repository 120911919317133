/* Rectangle 3 */
.full_about {
  position: relative;
  width: 100%;
  height: 45px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  padding-top: 100px;
  padding-bottom: 100px;
  line-height: 45px;
  text-align: center;
  color: #26c485;
}

/* important - override inline antd styles */
.about_box1 {
  margin: 0 0 60px auto !important;
}

.about_box2 {
  margin: 0 auto 60px 0 !important;
}

@media only screen and (max-width: 1000px) {
  .about_box1 {
    margin: auto auto 60px auto;
  }
  .about_box2 {
    margin: auto auto 60px auto;
  }
}

.titulo_about {
  position: relative;
  width: 100%;
  height: 100px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  padding-bottom: 100px;
  line-height: 191%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #353535;
}

.texto_about {
  /* Khadija Benis Khadija Benis is an architect and urban planner. She is currently a research associate at the MIT Sustainable Design Lab. She completed her PhD in Sustainable Energy Systems under the MIT Portugal Program. She developed a framework for the environmental and economic sustainability assessment of Building-Integrated Agriculture (BIA) in urban contexts. At MIT, she developed HARVEST, a CAD-embedded tool for urban food production simulation that works as a plugin for the Urban Modeling Interface (UMI). Christoph Reinhart Christoph Reinhart is a building scientist and architectural educator working in the field of sustainable building design and environmental modeling. At MIT he is leading the Sustainable Design Lab, an inter-disciplinary group with a grounding in architecture that develops design workflows, planning tools and metrics to evaluate the environmental performance of buildings and neighborhoods. */

  position: relative;
  width: 100%;
  padding-right: 10vw;
  padding-left: 10vw;
  padding-bottom: 100px;
  height: auto;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 191%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #353535;
}

.footer {
  position: absolute;
  width: 59px;
  height: 63px;
  left: 66px;
  top: 988px;

  background: url(../../img/LOGO.png);
  /* Contact us */

  position: absolute;
  width: 89px;
  height: 20.7px;
  left: 133px;
  top: 1009.2px;

  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  color: #000000;
}

@media only screen and (max-width: 600px) {
  .titulo_about {
    font-size: 27px;
  }

  .texto_about {
    font-size: 20px;
  }
}
