.main-form-background {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  width: 100%;
  height: auto;
  padding-bottom: 60px;
}
.select-text {
  margin-top: 200px;
  margin-left: 200px;
}
.main-form-title-small {
  padding-top: 48px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #009688;
}
.main-form-title-big {
  padding-top: 12px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  margin-bottom: 48px;

  color: #009688;
}

.main-form-editing-crop {
  padding-top: 12px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  margin-bottom: 48px;

  color: #009688;
}

.main-form-section-titles {
  color: #999;
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 24pxpx;
}
.form-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
}
.icon_plus {
  width: 50px;
  height: 50px;
}
.ant-select-selection {
  background-color: #fff;
}
