.foodButton {
  background: #cce6ea;
  transition: font-size 1s;
}

.foodButton:hover {
  background: #c7dde0;
  font-size: 16px;
}

.plusButton {
  background: white;
  font-size: 16px;
  transition: background 0.1s;
}
.plusButton:hover {
  background: #f4dea8;
  font-size: 16px;
}

.box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
}
.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
