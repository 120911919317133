.hero-container {
  display: flex;
  justify-content: center;
}

.hero_bg_image {
  width: 100vw;
  display: block;
  height: 100vh;
  background: url(../img/new/new_hero_home.png) no-repeat center center fixed;
  background-size: cover;
}

.new-hero {
  position: absolute;
  margin-top: 40vh;
  height: 100px;
  /* left: 25%; */
  /* margin-left: 25%; */
  width: 600px;
  background-repeat: no-repeat;
  z-index: 10;
  background-size: contain;
}

.readmore_button_hero {
  position: absolute;
  margin-top: 60vh;
  height: 50px;
  /* margin-left: 25%; */
  width: 50%;
  background-repeat: no-repeat;
  z-index: 10;
  background-size: contain;
}

.world-map {
  width: 80%;
  max-height: 100%;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.hero-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  justify-content: space-between;
  padding: 24px;
  min-height: 80px;
  height: auto;
  width: 100%;
}
.hero-footer_right {
  display: flex;

  padding-inline-end: 60px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: normal;

  color: #000000;
}
.hero-footer_left {
  padding-inline-start: 60px;
  display: flex;
}

.slick-prev {
  left: 20px;

  z-index: 1000;
}
.slick-next {
  right: 20px;
  z-index: 1000;
}
/* AFTER |UPGRADE */

@media only screen and (max-width: 600px) {
  .new-hero {
    background-size: contain;
    position: absolute;
    margin-left: 0px;
    margin-top: 10vh;
    height: 100px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 10;
  }

  .readmore_button_hero {
    margin-top: 30vh;
    height: 50px;
  }
}
