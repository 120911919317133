.picture-option {
  opacity: 1 !important;
}
strong {
  font-weight: bold !important;
}
.picture-option:hover {
  opacity: 0.8 !important;
  border-style: solid !important;
  border-color: #46645b !important;
  border-radius: 13px;
}

.picture-option-selected {
  opacity: 0.8 !important;
  border-style: solid !important;
  border-color: #000 !important;
  border-radius: 13px;
}

.ant-slider-track {
  background-color: #46645b;
  height: 10px;
  border-radius: 5px;
}

.ant-slider-rail {
  height: 10px;
  border-radius: 5px;
}

.ant-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #46645b;
}

.ant-slider:not {
  background-color: #46645b;
}
.ant-slider-handle :hover {
  width: 20px;
  height: 20px;
  margin-top: -5px;
  background-color: #fff !important;
  border: solid 2px #000;
}
.ant-slider-dot {
  width: 15px;
  height: 15px;
  margin-top: -1px;
}

.arrowAnimation {
  animation: arrow_moving 5s infinite ease;
}

@keyframes arrow_moving {
  0% {
    top: 487px;
  }

  50% {
    top: 450px;
  }

  100% {
    top: 487px;
  }
}
