@import "~antd/dist/antd.css";

@font-face {
  font-family: Quicksand;
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Quicksand-Regular.otf") format("opentype");
}
@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: url("fonts/Roboto-Regular.ttf") format("opentype");
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
a:link,
a:visited {
  color: #26c485;
}

.plateModal {
  max-width: 1200px;
}
