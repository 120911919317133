.main-form-title-small {
  padding-top: 48px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #009688;
}
.main-form-title-big {
  padding-top: 12px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  margin-bottom: 48px;

  color: #009688;
}

.research-background {
  min-height: 100vh;
  height: auto;
  padding-bottom: 60px;
}
.research-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
}

.icon-text-containter {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 150px;
  margin-top: 24px;
  margin-right: 60px;
}
.research-icons {
  width: 70px;
  height: auto;
  margin-top: 24px;
}
.research-titles {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-top: 24px;
  color: #009688;
}
.research-text {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  margin-top: 24px;

  color: #adb3cb;
}
