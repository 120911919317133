.ourWork_root {
  display: flex;
  background-color: #f6f6f6;
  /* max-width: 100vw; */
  /* min-height: 80vh; */
  height: auto;
  padding-top: 50px;
}
/* Rectangle 3 */
.title_ourWork {
  position: relative;
  width: 100%;
  height: 45px;

  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  padding-top: 100px;
  padding-bottom: 100px;
  line-height: 45px;
  text-align: center;
  color: #26c485;
}

.container {
  position: absolute;
  bottom: 13vh;
  left: 16px;
}
.harvest_text {
  width: 70%;
  padding: 30px;
  /* padding: 60vh; */
}
.harvest_container {
  width: 100%;
  height: 60vh;
  margin-top: 10vh;
  padding: 40px;
  text-align: center;
}

.ourWork_bg_image {
  /* width: 100vw; */
  display: block;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url(../../img/new/bg.png) no-repeat center center fixed;
  background-size: cover;
  padding: 50px;
}
.try_harvest {
  width: 100%;
  height: 50vh;
}

.try_harvest_container {
  height: 50vh;
  text-align: center;
}

.tryHarvesText {
  margin-top: 10vh;
  height: 50px;
  width: 100%;
}
.tryHarvest_Download {
  margin-top: 10vh;
}
.tryHarvest_Watch {
  margin-top: 10vh;
}

@media only screen and (max-width: 600px) {
  .ourWork_bg_image {
    padding-top: 5vh;
    min-height: 100vh;
    height: auto;
  }
  .container {
    height: auto;
    bottom: 5vh;
    padding: 1vh;
  }
  .harvest_text {
    padding-top: 10vh;
    padding-left: 1vh;
    padding-right: 1vh;
    width: 100%;
  }
  .try_harvest {
    width: 100%;
    height: 20vh;
  }
  .harvest_container {
    margin: 0;
    width: 100%;
    height: auto;
    padding: 0;
    text-align: center;
  }
}

.harvest {
  width: 100vw;
  display: block;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url(../../img/new/harvest.png) no-repeat center center fixed;
  background-size: cover;
}
